.page{
  min-width: 1200px;
}
.top{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 1200px;
  z-index: 3;
}
.btm{
  margin-top: 90px;
}
.nav{
  width: 100%;
  height: 60px;
  background: #1D1D22;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  .con{
      width: 100%;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 0 30px;
      font-weight: bold;
      .logo{
          float: left;
          width: 200px;
          height: 40px;
          margin-top: 6px;
          background-repeat: no-repeat;
          background-position: left center;
          background-image: url('../../assets/logo.png');
          background-size: contain;
          cursor: pointer;
          position: relative;
          margin: 10px 30px 0 0;
      }
      .logo:after{
          content: '';
          display: block;
          position: absolute;
          top: 9px;
          right: 0;
          width: 1px;
          height: 30px;
          background: #C1C1C9;
      }
      ul{
          float: left;
          li{
              float: left;
              line-height: 60px;
              font-size: 14px;
              font-family: SFProText-Medium, SFProText;
              margin-right: 53px;
              cursor: pointer;
              a{
                  color: #838383;
              }
              .item{
                  width: 100%;
                  height: 60px;
              }
          }
          .current a{
              color: #48bfe2;
          }
      }
      .out{
          float: right;
          line-height: 60px;
          cursor: pointer;
          font-size: 14px;
          font-family: SFProText-Medium, SFProText;
          color: #fff;
      }
  }
}
.main{
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px 50px 30px;
  .load{
      text-align: center;
      font-size: 30px;
  }
  .loadEffect {
  width: 28px;
  height: 28px;
  margin: 50px auto;
  position: relative;
}
.loadEffect div{
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-animation: load 2.08s linear infinite;
}
.loadEffect div span{
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #46badc;
  position: absolute;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
}
@keyframes load{
  0%{
    -webkit-transform: rotate(0deg);
  }
  10%{
    -webkit-transform: rotate(45deg);
  }
  50%{
    opacity: 1;
    -webkit-transform: rotate(160deg);
  }
  62%{
    opacity: 0;
  }
  65%{
    opacity: 0;
    -webkit-transform: rotate(200deg);
  }
  90%{
    -webkit-transform: rotate(340deg);
  }
  100%{
    -webkit-transform: rotate(360deg);
  }

}
.loadEffect div:nth-child(1){
  -webkit-animation-delay:0.2s;
}
.loadEffect div:nth-child(2){
  -webkit-animation-delay:0.4s;
}
.loadEffect div:nth-child(3){
  -webkit-animation-delay:0.6s;
}
.loadEffect div:nth-child(4){
  -webkit-animation-delay:0.8s;
}
  .search{
      width: 100%;
      margin-bottom: 30px;
      .date{
          float: left;
          .ant-picker-input > input{
              line-height: 30px;
              font-size: 16px;
              width: 100px;
          }
      }
      .input{
          float: right;
          position: relative;
          i{
              position: absolute;
              left: 7px;
              top: 7px;
              font-size: 16px;
              z-index: 2;
              color: #d9d9d9;
          }
          .ant-input{
              line-height: 30px;
              padding-left: 30px;
              font-size: 16px;
          }
      }
      .back{
        width: 100px;
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 30px;
        cursor: pointer;
        float: left;
        span{
            margin-right: 10px;
        }
      }
      .create{
        margin-bottom: 30px;
        margin-left: 30px;
        button{
            border: none;
            background: none;
            outline: none;
            border-radius: 3px;
            line-height: 40px;
            font-size: 16px;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
            padding: 0 10px;
            color: #777474;
            cursor: pointer;
            span{
                margin-right: 10px;
            }
        }
      }
  }
  .list{
      margin-bottom: 30px;
      .red-dot{
          padding-left: 20px;
          position: relative;
          &:after{
              content: '';
              display: block;
              width: 8px;
              height: 8px;
              background: #f55858;
              border-radius: 50%;
              position: absolute;
              left: 0;
              top: 8px;
          }
      }
      .green-dot{
          padding-left: 20px;
          position: relative;
          &:after{
              content: '';
              display: block;
              width: 8px;
              height: 8px;
              background: #2bd62b;
              border-radius: 50%;
              position: absolute;
              left: 0;
              top: 8px;
          }
      }
      .btn{
          background: none;
          border: none;
          outline: none;
          line-height: 30px;
          font-size: 16px;
          width: 90px;
          border-radius: 3px;
          margin-right: 10px;
          cursor: pointer;
          span{
              margin-right: 5px;
          }
          &.ena{
              border: 1px solid #2bd62b;
              color: #2bd62b;
              width: 100px;
          }
          &.dis, &.del{
              border: 1px solid #f55858;
              color: #f55858;
              width: 100px;
          }
          &:nth--last-child(1){
              margin: 0;
          }
          &.send{
              width: 150px;
              border: 1px solid #2bd62b;
              color: #2bd62b;
              display: block;
              text-decoration: none;
              text-align:center;
          }
      }
      .none{
          display: none;
      }
      .ant-space-item{
          margin: 0 !important;
      }
      .img{
          width: 125px;
          height: 71px;
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
          background-color: #f3f3f3;
      }
  }
  .page{
      text-align: center;
  }
  .create{
      margin-bottom: 30px;
      button{
          border: none;
          background: none;
          outline: none;
          border-radius: 3px;
          line-height: 40px;
          font-size: 16px;
          box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
          padding: 0 10px;
          color: #777474;
          cursor: pointer;
          span{
              margin-right: 10px;
          }
      }
  }
  .back{
      width: 100px;
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 30px;
      cursor: pointer;
      span{
          margin-right: 10px;
      }
  }
  .box{
      width: 900px;
      margin: 0 auto;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      border-radius: 3px;
      padding: 20px;
      h1{
          font-size: 18px;
          border-bottom: 1px solid #f3f3f3;
          line-height: 18px;
          padding-bottom: 20px;
          margin-bottom: 30px;
      }
      h3{
        font-size: 18px;
        line-height: 18px;
        padding-bottom: 10px;
      }
      h4{
        font-size: 14px;
        line-height: 16px;
        padding-bottom: 10px;
        margin-bottom: 30px;
        border-bottom: 1px solid #f3f3f3;
      }
}
  .tab{
      width: 100%;
      margin-bottom: 30px;
      .tit{
          font-size: 18px;
          line-height: 18px;
          padding-bottom: 20px;
          float: left;
          margin-right: 30px;
          position: relative;
          cursor: pointer;
          &:after{
              content: '';
              display: block;
              width: 0;
              height: 3px;
              background: #000;
              position: absolute;
              left: 0 ;
              bottom: 0;
              overflow: hidden;
              transition: all .6s;
          }
      }
      .active:after{
          width: 100%;
      }
  }
  .upload{
      width: 200px;
      height: 200px;
      margin-right: 8px;
      margin-bottom: 8px;
      text-align: center;
      vertical-align: top;
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
      border-radius: 2px;
      cursor: pointer;
      -webkit-transition: border-color .3s;
      transition: border-color .3s;
      span{
          margin-top: 60px;
      }
  }
  .upload-area{
      position: relative;
      .loading{
          position: absolute;
          width: 200px;
          height: 200px;
          left: 0;
          top: 0;
          background: #fff;
          text-align: center;
          font-size: 30px;
          border: 1px dashed #d9d9d9;
          background-color: #fafafa;
          span{
              margin-top: 60px;
          }
      }
      .img{
          position: absolute;
          width: 200px;
          height: 200px;
          left: 0;
          top: 0;
          background: #fff;
          text-align: center;
          font-size: 30px;
          border: 1px dashed #d9d9d9;
          background-color: #fafafa;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;
      }
  }
}
.picWrap{
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 8;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .opetation{
      width: 623px;
      height: 430px;
      background: #fff;
      box-shadow:0px 25px 70px 0px rgba(0,0,0,0.1);
      border-radius:5px; 
      box-sizing: border-box;
      padding: 30px;
      position:relative;
      .drag{
          width: 562.5px;
          height: 318px;
          border: 1px dashed #ccc;
          position: relative;
          overflow: hidden;
          z-index: 3;
          margin-bottom: 20px;
      }
      img{
          position: absolute;
      }
      .canvas{
          position: absolute;
          left: 30px;
          top: 30px;
      }
  }
  .btns{
      width: 100%;
      .btn{
          width:100px;
          height:40px;
          float: right;
          cursor: pointer;
          border-radius:5px;
          font-size:20px;
          line-height: 40px;
          text-align:center;
          border: 1px solid #55bae9;
          color: #55bae9;
      }
      .btn2{
          width:100px;
          height:40px;
          float: right;
          cursor: pointer;
          border-radius:5px;
          font-size:20px;
          line-height: 40px;
          text-align:center;
          border: 1px solid #333;
          color: #333;
          margin-right: 20px;
      }
  }
}
.none{
  display: none;
}
.text{
  font-size: 18px;
  text-align: center;
  padding-top: 20px;
  font-family: SFProText-Regular,SFProText;
}
.loadmask{
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content:center;
  background: rgba(255, 255, 255, .3);
  z-index: 10;
}
.nowrap{
  min-width: 120px;
}
.recude{
  max-width: 768px;
  margin: 0 auto;
  padding-bottom: 50px;
  .logo{
      width: 37%;
      padding-bottom: 10.09%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      background-image: url('../../assets/logo2.png');
      margin: 30px auto;
    }
    .logo2{
      width: 721px;
      height: 40px;
      background-repeat: no-repeat;
      background-position: left center;
      background-size: contain;
      background-image: url('../../assets/logo2.png');
      margin: 30px auto;
    }
    .wrap{
      width: 100%;
      box-sizing: border-box;
      padding: 0 24px;
      .warn{
        width: 100%;
        box-sizing: border-box;
        padding: 12px 16px;
        background: rgba(255, 98, 98, 0.08);
        border-radius: 6px;
        border: 1px solid #FF6262;
        font-size: 15px;
        font-weight: 600;
        color: #FF6262;
        line-height: 23px;
        margin-bottom: 32px;
        text-align: center;
      }
      h1{
        font-size: 17px;
        color: #000000;
        line-height: 20px;
        margin-bottom: 16px;
        font-weight: 600;
        span{
          color: #5E5E64;
        }
      }
      h2{
        font-size: 15px;
        font-family: Rubik-Regular, Rubik;
        font-weight: 600;
        color: #5E5E64;
        line-height: 18px;
        margin-bottom: 12px;
      }
      .pic{
        width: 100%;
        li{
          width: 29.84%;
          float: left;
          margin: 0 5.2% 5.2% 0;
          background: #f5f5f5;
          position: relative;
          &:nth-child(3n){
            margin-right: 0;
          }
          a{
            display: block;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
          }
        }
      }
      .loading{
          text-align:center;
          margin-bottom: 5.2%;
      }
      .radio{
        width: 100%;
        margin-bottom: 8px;
        li{
          width: 100%;
          height: 52px;
          background: #ECECF0;
          border-radius: 6px;
          display: flex;
          flex-direction: row;
          margin-bottom: 8px;
          &:nth-last-child(1){
            margin-bottom: 16px;
          }
          .icon{
            flex: 0 0 auto;
            width: 28px;
            height: 28px;
            margin: 12px 16px 0 12px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            background-image: url('../../assets/play.png');
          }
          .icon2{
            flex: 0 0 auto;
            width: 28px;
            height: 28px;
            margin: 12px 16px 0 12px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            background-image: url('../../assets/stop.png');
          }
          .wave{
            flex: 1;
            height: 30px;
            overflow: hidden;
            position:relative;
            margin-top: 11px;
            background-image: url('../../assets/wave.png');
            background-size: auto 30px;
          }
          .time{
            flex: 0 0 auto;
            margin: 0 13px;
            line-height: 52px;
            font-size: 11px;
            font-family: Rubik-Regular, Rubik;
            font-weight: 400;
            color: #A3A3AE;
          }
          audio{
            z-index: -1;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
      ul:after{
        content: '';
        display: block;
        width: 0;
        height: 0;
        clear: both;
      }
      .load{
        display: block;
        width: 100%;
        height: 46px;
        border-radius: 6px;
        border: 1px solid #3063F0;
        color: #3063F0;
        margin-bottom: 24px;
        background: none;
        outline: none;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
        line-height: 46px;
        text-align:center;
      }
      .address{
        width: 100%;
        padding-right: 78px;
        box-sizing: border-box;
        position: relative;
        margin-bottom: 21px;
        min-height: 30px;
        .text2{
          font-size: 15px;
          font-family: Rubik-Regular, Rubik;
          font-weight: 400;
          color: #5E5E64;
          line-height: 18px;
        }
        .copy{
          padding: 0 10px;
          height: 26px;
          border-radius: 4px;
          border: 1px solid #3063F0;
          position: absolute;
          top: 0;
          right: 0;
          background: none;
          outline: none;
          font-size: 14px;
          font-family: Rubik-Regular, Rubik;
          font-weight: 400;
          color: #3063F0;
        }
      }
      .map{
        width: 100%;
        border-radius: 6px;
        height: 216px;
        background: paleturquoise;
        margin-bottom: 40px;
        position:relative;
      }
      .jump{
        position:absolute;
        right: 16px;
        bottom: 16px;
        width: 36px;
        height: 36px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        background-image: url('../ed.png');
        cursor:pointer;
      }
      @media screen and (min-width: 768px) {
          .map{
            height: 443px;
          }
      }
      @media screen and (max-width: 768px) {
          .map{
            height: 443px;
          }
      }
      @media screen and (max-width: 600px) {
          .map{
            height: 320px;
          }
      }
      @media screen and (max-width: 460px) {
          .map{
            height: 260px;
          }
      }
      @media screen and (max-width: 400px) {
          .map{
            height: 230px;
          }
      }
      @media screen and (max-width: 380px) {
          .map{
            height: 216px;
          }
      }
      @media screen and (max-width: 330px) {
          .map{
            height: 185px;
          }
      }
    }
    .wrap2{
      width: 721px;
      box-sizing: border-box;
      margin: 0 auto;
      .warn{
        width: 100%;
        box-sizing: border-box;
        padding: 12px 16px;
        background: rgba(255, 98, 98, 0.08);
        border-radius: 6px;
        border: 1px solid #FF6262;
        font-size: 15px;
        font-weight: 600;
        color: #FF6262;
        line-height: 23px;
        margin-bottom: 32px;
        text-align: center;
      }
      h1{
        font-size: 17px;
        color: #000000;
        line-height: 20px;
        margin-bottom: 16px;
        font-weight: 600;
        span{
          color: #5E5E64;
        }
      }
      .picarea{
          margin-bottom: 48px;
      }
      .piclft{
          float: left;
          width: 328px;
      }
      .picrgt{
          float: right;
          width: 328px;
      }
      h2{
        font-size: 15px;
        font-family: Rubik-Regular, Rubik;
        font-weight: 600;
        color: #5E5E64;
        line-height: 18px;
        margin-bottom: 12px;
      }
      .pic{
        width: 100%;
        li{
          width: 29.84%;
          float: left;
          margin: 0 5.2% 5.2% 0;
          background: #f5f5f5;
          position: relative;
          &:nth-child(3n){
            margin-right: 0;
          }
          a{
            display: block;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
          }
        }
      }
      .loading{
          text-align:center;
          margin-bottom: 5.2%;
      }
      .radio{
        width: 100%;
        margin-bottom: 8px;
        li{
          width: 100%;
          height: 52px;
          background: #ECECF0;
          border-radius: 6px;
          display: flex;
          flex-direction: row;
          margin-bottom: 8px;
          &:nth-last-child(1){
            margin-bottom: 16px;
          }
          .icon{
            flex: 0 0 auto;
            width: 28px;
            height: 28px;
            margin: 12px 16px 0 12px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            background-image: url('../../assets/play.png');
          }
          .icon2{
            flex: 0 0 auto;
            width: 28px;
            height: 28px;
            margin: 12px 16px 0 12px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            background-image: url('../../assets/stop.png');
          }
          .wave{
            flex: 1;
            height: 30px;
            overflow: hidden;
            position:relative;
            margin-top: 11px;
            background-image: url('../../assets/wave.png');
            background-size: auto 30px;
          }
          .time{
            flex: 0 0 auto;
            margin: 0 13px;
            line-height: 52px;
            font-size: 11px;
            font-family: Rubik-Regular, Rubik;
            font-weight: 400;
            color: #A3A3AE;
          }
          audio{
            z-index: -1;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
      ul:after{
        content: '';
        display: block;
        width: 0;
        height: 0;
        clear: both;
      }
      .load{
        display: block;
        width: 100%;
        height: 46px;
        border-radius: 6px;
        border: 1px solid #3063F0;
        color: #3063F0;
        background: none;
        outline: none;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
        line-height: 46px;
        text-align:center;
      }
      .address{
        width: 100%;
        padding-right: 111px;
        box-sizing: border-box;
        position: relative;
        margin-bottom: 21px;
        min-height: 30px;
        .text2{
          font-size: 15px;
          font-family: Rubik-Regular, Rubik;
          font-weight: 400;
          color: #5E5E64;
          line-height: 18px;
        }
        .copy{
          padding: 0 10px;
          height: 26px;
          border-radius: 4px;
          border: 1px solid #3063F0;
          position: absolute;
          top: 0;
          right: 0;
          background: none;
          outline: none;
          font-size: 14px;
          font-family: Rubik-Regular, Rubik;
          font-weight: 400;
          color: #3063F0;
        }
      }
      .map{
        width: 100%;
        border-radius: 6px;
        height: 216px;
        background: paleturquoise;
        margin-bottom: 40px;
      }
      .jump{
        position:absolute;
        right: 16px;
        bottom: 16px;
        width: 36px;
        height: 36px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        background-image: url('../ed.png');
        cursor:pointer;
      }
    }
}

.dark{
  width: 100%;
  min-height: 100vh;
  background: #1D1D22;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  .hidden {
    height: 0;
    width: 0 !important;
    overflow: hidden !important;
    min-width: 0 !important;
  }
  .logo{
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url('../logo.png');
  }
  h1{
      font-size: 22px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 27px;
      margin: 0 auto 50px auto;
  }
  h3 {
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 20px;
    text-align: center;
    margin-bottom: 120px;
  }
  .tip-off {
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 22px;
    text-align: center
  }
  .mar-btm-30 {
    margin-bottom: 30px;
  }
  .mar-btm-58 {
    margin-bottom: 58px;
  }
  .align-center {
    text-align: center;
  }
  .smaller-margin-bottom {
    margin: 0 auto 36px auto;
  }
  h2{
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 17px;
  }
  .input-item{
      width: 100%;
      margin: 0 auto 30px auto;
      border-bottom: 1px solid #777785;
      position: relative;
      input{
          width: 100%;
          height: 39px;
          border: none;
          outline: none;
          font-size: 16px;
          color: #fff;
          background: none;
      }
  }
  .input-item:after{
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: -1px;
      background: #475D9A;
      width: 0;
      height: 1px;
      transition: all .3s;
  }
  .blue:after{
      width: 100%;
  }
  button{
      display: block;
      border: none;
      outline: none;
      height: 48px;
      background: #3063F0;
      border-radius: 6px;
      line-height: 48px;
      font-size: 14px;
      font-weight: 500;
      color: #FFFFFF;
      margin: 0 auto;
      cursor: pointer;
      &.disabled-button {
        opacity: 0.6;
        cursor: not-allowed;
      }
  }
  .mar-btm {
    margin-bottom: 50px;
  }
  .ant-spin-spinning{
      margin-left: 5px;
      position: relative;
      top: 3px;
  }
  .ant-spin-dot-item{
      background-color: #fff;
  }
  p{
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 17px;
  }
  .other-method {
    font-weight: 400;
    font-size: 15px;
    color: #A3A3AE;
    line-height: 18px;
    text-align: center;
    margin-bottom: 19px;
    display: grid;
    grid-template-columns: 1fr 136px 1fr;
    grid-template-areas:
    'line1 txt line2';
  }
  .line1 {
    grid-template-areas: line1;
    width: 100%;
    border-top: 1px solid #A3A3AE;
    position: relative;
    top: 8px;
  }
  .txt {
    grid-template-areas: txt;
  }
  .line2 {
    grid-template-areas: line2;
    width: 100%;
    border-top: 1px solid #A3A3AE;
    position: relative;
    top: 8px;
  }
  .google-login{
    width: 54px;
    height: 54px;
    margin: 0 auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../assets/gg.png');
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
  #buttonDiv {
    opacity: 0;
  }
  .S9gUrf-YoZ4jf {
    height: 54px;
     // opacity: 0;
  }
}
.outer h1 {
  font-weight: 600 !important;
}
@media screen and (min-width: 768px) {
  .dark{
      .outer{
          width: 730px;
      }
      .logo{
          width: 180px;
          height: 50px;
          margin: 0 auto 80px auto;
      }
      .box{
          width: 730px;
          margin: 0 auto;
          background: #28282D;
          border-radius: 6px;
          padding: 60px 0 60px 0;
      }
      .in{
          width: 472px;
          margin: 0 auto;
      }
      button{
          width: 280px;
      }
      p{
          padding-bottom: 227px;
      }
  }
}
@media screen and (max-width: 768px) {
  .dark{
      .outer{
          width: 100%;
          box-sizing: border-box;
          padding: 0 24px;
          min-width: 320px;
      }
      .logo{
          width: 140px;
          height: 38px;
          margin: 0 auto 40px auto;
      }
      .box{
          width: 100%;
          box-sizing: border-box;
          padding: 40px 24px 40px 24px;
          background: #28282D;
          border-radius: 6px;
      }
      .in{
          width: 100%;
          margin: 0 auto;
      }
      button{
          width: 100%;
      }
      .mar-btm {
        margin-bottom: 30px;
      }
      p{
          padding-bottom: 140px;
      }
  }
}

.recude2{
  max-width: 768px;
  margin: 0 auto;
  padding-bottom: 50px;
  position: relative;
  height: 100vh;
  width: 100%;
  min-width: 320px;
  .logo{
      width: 140px;
      padding-bottom: 32px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      background-image: url('../../assets/logo2.png');
      margin: 30px auto;
    }
    .wrap{
      width: 100%;
      box-sizing: border-box;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 50px;
      .map{
        border-radius: 6px;
        background: paleturquoise;
        position:absolute;
        left: 24px;
        right: 24px;
        top: 0;
        bottom: 50px;
      }
      .jump{
        position:absolute;
        right: 36px;
        bottom: 60px;
        width: 36px;
        height: 36px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        background-image: url('../ed.png');
        cursor:pointer;
      }
    }
}
.wtxt{
position: relative;
}
.htxt{
width: 400px;
span{
  display: inline-block;
  width:100%;
}
}
.hsix{
width: 400px;
-webkit-line-clamp: 3;
display: -webkit-box;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
height: 66px;
span{
  display: inline-block;
  width:100%;
  max-height: 67px;
  overflow: hidden;
}
}
.hd{
width: 400px;
-webkit-line-clamp: 3;
display: -webkit-box;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
max-height: 66px;
span{
  display: inline-block;
  width:100%;
  max-height: 67px;
  overflow: hidden;
}
}
.more{
position: absolute;
left: 0;
top: 60px;
background: #fff;
color: blue;
font-size: 14px;
background: #fff;
cursor: pointer;
}
.none{
display: none;
}

.al-wp{
width: 100%;
height: 100vh;
position: fixed;
left: 0;
top: 0;
bottom: 0;
background: rgba(0, 0, 0, .5);
z-index: 10;
.al-bg{
  position: absolute;
  top: 50%;
  left: 4%;
  width: 92%;
  background: #2d2d2d;
  margin-top: -4.5rem;
  .line{
    width: 100%;
    line-height: 3rem;
    height: 3rem;
    background: #2d2d2d;
    font-size: 1.2rem;
    text-align:center;
    color: #fff;
    cursor:pointer;
    border-bottom: 1px solid #000;
  }
}
}
.music{
width: 100%;
height: 300px;
}
.radioLines{
margin: 5px 0 20px 0;
}
.radioInput{
position: relative;
width: 230px;
.tag{
  position: absolute;
  top: 0;
  right: 0;
  line-height: 32px;
  color: #333;
  font-size: 16px;
}
}
.extraInput{
position: relative;
.tag{
  position: absolute;
  top: 0;
  right: 5px;
  line-height: 32px;
  color: #333;
  font-size: 16px;
}
}
.digitInput{
position: relative;
width: 100%;
.btn{
  position: absolute;
  top: 0;
  right: 148px;
  line-height: 32px;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  .anticon{
    margin-right: 5px
  }
}
}
.random{
cursor: pointer;
.anticon{
  margin-right: 5px
}
}
.warn-promo{
border-color: #ff4d4f !important;
}
.upload-area2{
position: relative;
margin-bottom: 30px;
.ant-upload{
  width: 100%;
}
.upload{
  width: 100%;
  border: 1px solid #ccc;
  box-sizing: border-box;
  text-align: center;
  height: 200px;
  box-sizing: border-box;
  padding-top: 50px;
}
.loading{
    position: absolute;
    width: 100%;
    height: 300px;
    left: 0;
    top: 0;
    background: #fff;
    text-align: center;
    font-size: 30px;
    border: 1px dashed #d9d9d9;
    background-color: #fafafa;
    span{
        margin-top: 60px;
    }
}
.image-wrap{
  width: 100%;
  border: 1px solid #ccc;
  box-sizing: border-box;
  text-align: center;
  height: 200px;
  box-sizing: border-box;
  padding-top: 30px;
  position: relative;
  h1{
    font-size: 18px;
  }
  .anticon{
    margin-right: 10px;
    position: relative;
    top: 2px;
  }
  .icon{
    position: absolute;
    right: -23px;
    top: -25px;
    cursor: pointer;
    font-size: 30px;
    background: #fff;
  }
}
}
.btn-confirm{
text-align:center;
}
.upload-more{
h1{
  font-size: 14px;
  margin-bottom: 20px;
  word-break: break-word;
}
}
.excl{
  width: 100%;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('../../assets/icon.png');
  background-size: contain;
  margin-bottom: 20px;
}
.sex1{
  width: 16px;
  height: 16px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('../../assets/u.png');
  background-size: contain;
  margin-left: 10px;
  position: relative;
  top: 3px;
}
.sex2{
  width: 16px;
  height: 16px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('../../assets/m.png');
  background-size: contain;
  margin-left: 10px;
  position: relative;
  top: 3px;
}
.sex3{
  width: 16px;
  height: 16px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('../../assets/w.png');
  background-size: contain;
  margin-left: 10px;
  position: relative;
  top: 3px;
}
.delete-wrap {
  .ant-modal-header, .ant-modal-footer, .ant-modal-close {
    display: none !important;
  }
  .ant-modal-content {
    border-radius: 16px !important;
  }
  .ant-modal-body {
    padding: 24px 32px !important;
  }
  h1 {
    font-family: Rubik, Rubik;
    font-weight: 600;
    font-size: 17px;
    color: #1D1D22;
    line-height: 20px;
    text-align: center;
    margin-bottom: 26px;
  }
  .txt {
    font-family: Rubik, Rubik;
    font-weight: 400;
    font-size: 15px;
    color: #1D1D22;
    line-height: 18px;
    text-align: center;
    margin-bottom: 40px;
  }
  .btn-confirm {
    float: left;
    width: 175px;
    .ant-btn {
      width: 100%;
      height: 45px;
      background-color: #3063F0 !important;
      border-color: #3063F0 !important;
      border-radius: 6px;
      color: #ffffff !important;
      font-family: Rubik, Rubik;
      font-weight: 600;
      font-size: 14px;
      .ant-spin-spinning {
        margin-left: 10px;
      }
    }
  }
  .btn-cancel {
    float: right;
    width: 175px;
    .ant-btn {
      width: 100%;
      height: 45px;
      background-color: #ffffff !important;
      border-color: #3063F0 !important;
      border-radius: 6px;
      color: #3063F0 !important;
      font-family: Rubik, Rubik;
      font-weight: 600;
      font-size: 14px;
    }
  }
}