.bdg{
    width: 100%;
    height: 100vh;
    background: #F5F9FC;
    position: relative;
    padding-top: 200px;
    box-sizing: border-box;
    .box{
        position: absolute;
        left: 50%;
        top: 0;
        width: 400px;
        height: 400px;
        background: #FFFFFF;
        box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        margin: 0 0 0 -200px;
        box-sizing: border-box;
        padding: 50px 45px 0;
        position: relative;
        h1{
            text-align: center;    
            line-height: 38px;
            font-size: 32px;
            font-weight: 400;
            color: #1B1B1B;
            margin-bottom: 50px;
            height: 40px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            background-image: url('../logo2.png');
        }
        .input-item{
            width: 310px;
            margin: 0 auto;
            border-bottom: 1px solid #D6D6DB;
            position: relative;
            input{
                width: 100%;
                height: 39px;
                border: none;
                outline: none;
                font-size: 16px;
                color: #1B1B1B;
            }
        }
        .input-item:after{
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: -1px;
            background: #475D9A;
            width: 0;
            height: 1px;
            transition: all .3s;
        }
        .blue:after{
            width: 100%;
        }
        button{
            display: block;
            margin: 50px auto 0;
            outline: none;
            border: none;
            width: 150px;
            height: 40px;
            background: #475D9A;
            border-radius: 20px;
            font-size: 14px;
            font-family: SFProText-Semibold, SFProText;
            font-weight: 600;
            color: #FFFFFF;
            cursor: pointer;
        }
    }
    .ant-spin-spinning{
        margin-left: 5px;
        position: relative;
        top: 3px;
    }
    .ant-spin-dot-item{
        background-color: #fff;
    }
}