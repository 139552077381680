@font-face {  
    font-family: SFProText;
    src: url('SF-Pro-Text-Light.otf');
    font-weight: normal;  
    font-style: normal;  
}
@font-face {  
    font-family: SFProText-Regular;
    src: url('SF-Pro-Text-Regular.otf');
    font-weight: normal;  
    font-style: normal;  
}
@font-face {  
    font-family: SFProText-Semibold;
    src: url('SF-Pro-Text-Bold.otf');
    font-weight: normal;  
    font-style: normal;  
}
@font-face {  
    font-family: SFProText-Medium;
    src: url('SF-Pro-Text-Medium.otf');
    font-weight: normal;  
    font-style: normal;  
}