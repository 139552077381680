ul{
  margin: 0 !important;
  padding: 0;
  list-style-type: none;
}
h1, h2, h3, h4, h5, p{
  margin: 0;
  padding: 0;
}
.clear:after{
  content: '';
  width: 0;
  height: 0;
  display: block;
  clear: both;
}
.App{
  font-family: SFProText-Regular,SFProText;
}